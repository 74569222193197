import {
  HashRouter as Router,
  useRoutes,
} from 'react-router-dom';
import Home from './Home'
import History from './History'
import Qrcode from './Qrcode'

function AppRoutes() {
  const routes = useRoutes(
    [
      {path:'/',element:<Home/>},
      {path:'/history',element:<History/>},
      {path:'/qrcode',element:<Qrcode/>},
    ]
  )
  return routes;
}

export default function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}