import { Suspense, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import * as React from 'react';
import Button from "@mui/material/Button"
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import classNames from 'classnames';
import { useFBX, ArcballControls, Stage, Environment, Lightformer, Striplight } from "@react-three/drei";


const Model = (props) => {
  const ref = useRef()
  useFrame((state, delta) => {
    ref.current.rotation.z += 0.01;
  })
  const fbx = useFBX(props.name);
  return <primitive {...props} ref={ref} object={fbx} />;
};

const modelList = [
  {
    name: "定窑白釉刻莲瓣纹盖罐",
    url: "/assets/models/dingyao.fbx",
    introduce: '定窑创烧于唐，极盛于宋，终于元，烧造时间近700年，以产白瓷著称。定窑善用印花、刻花等装饰技法，花纹千姿百态',
    position: [0, 0.08, 0],
    rotationX: 2.6,
    scale: 0.08,
    id: "1",
    chance: 15,
  }, {
    name: "哥窑青釉盘",
    url: "/assets/models/geyao.fbx",
    introduce: '哥窑的主要特征是釉面有大小不规则的开裂纹片，俗称“开片”。釉面中细纹色黄，粗纹黑褐色，俗称“金丝铁线”。哥窑的具体的窑址至今还是一个谜。',
    position: [0, 0.3, 0],
    rotationX: 2.5,
    id: "2",
    scale: 0.03,
    chance: 15,
  }, {
    name: "南宋官窑杯",
    url: "/assets/models/guanyao.fbx",
    introduce: '官窑是宋朝专烧宫廷用瓷的窑厂。器物釉层丰厚，有粉青、米黄、青灰等色；釉面开片，器物口沿和底足露胎，有‘紫口铁足’之称。宋朝官窑分做两个地方，一为北宋的汴京官窑，一为南宋南迁后高宗在杭州另立的新窑',
    position: [0, 0.1, 0],
    id: "3",
    rotationX: 2.8,
    scale: 0.08,
    chance: 15,
  }, {
    name: "钧窑玫瑰紫釉渣斗式花盆",
    url: "/assets/models/junyao.fbx",
    introduce: '钧窑的釉色为一绝，红、蓝、青、白、紫交相融汇，千变万化。这是因为在烧制过程中，配料掺入铜的气化物造成的艺术效果，此为中国制瓷史上的一大发明，称为“窑变”。',
    position: [0, 0.1, 0],
    id: "4",
    rotationX: 2.5,
    scale: 0.02,
    chance: 10,
  }, {
    name: "汝窑天青无纹椭圆水仙花盆",
    url: "/assets/models/ruyao.fbx",
    introduce: '汝瓷属五大名窑之首，是北宋晚期的官窑，又称北宋官窑。釉色以天青为主，胎体较薄，釉层较厚，有玉石般的质感。汝窑作品传世不足百件，及其珍贵。',
    id: "5",
    position: [0, 0.25, 0],
    rotationX: 2.5,
    scale: 0.03,
    chance: 10,
  }, {
    name: "芙蓉仙子燕飞来伞",
    url: "/assets/models/san01.fbx",
    introduce: '在宋代，雨伞已成为一种真正的大众产品，除特权阶层外，普通百姓也可以使用， 当人们外出时，雨伞已成为人们行李中不可或缺的一部分，可以用来遮雨挡雨。南宋之都临安的雨伞业非常发达，成为是全国的制伞中心',
    position: [0, 0.3, 0],
    rotationX: 2,
    scale: 0.009,
    id: "6",
    chance: 5,
  }, {
    name: "忽如一夜春风来伞",
    url: "/assets/models/san02.fbx",
    introduce: '在宋代，雨伞已成为一种真正的大众产品，除特权阶层外，普通百姓也可以使用， 当人们外出时，雨伞已成为人们行李中不可或缺的一部分，可以用来遮雨挡雨。南宋之都临安的雨伞业非常发达，成为是全国的制伞中心',
    position: [0, 0.3, 0],
    rotationX: 2,
    id: "7",
    scale: 0.009,
    chance: 5,
  }, {
    name: "缠枝葡萄纹丝绸",
    url: "/assets/models/sichou01.fbx",
    introduce: '南宋临安城是丝绸之都，不仅是生产中心也是流通枢纽。宋朝受程朱理学影响，封建卫道观念上升，思想趋向内敛。宋朝丝绸配色以茶色、褐色、棕色、藕色等间色或复色为基调，配上白色，淡雅自然、风格庄重',
    position: [0, 0.05, 0],
    rotationX: 2.5,
    id: "8",
    scale: 0.0026,
    chance: 5,
  }, {
    name: "重莲纹丝绸",
    url: "/assets/models/sichou02.fbx",
    introduce: '南宋临安城是丝绸之都，不仅是生产中心也是流通枢纽。宋朝受程朱理学影响，封建卫道观念上升，思想趋向内敛。宋朝丝绸配色以茶色、褐色、棕色、藕色等间色或复色为基调，配上白色，淡雅自然、风格庄重',
    position: [0, 0.05, 0],
    rotationX: 2.5,
    id: "9",
    scale: 0.0026,
    chance: 5,
  }, {
    name: "白茶花团扇",
    url: "/assets/models/tuansan01.fbx",
    introduce: '团扇，是中国汉族传统工艺品及艺术品，具有小巧轻盈，温柔飘逸的特点。两宋时期，团扇已成为每个人手中的好物件。上至皇家贵妃，下至黎民百姓',
    position: [0, -0.2, 0],
    rotationX: 2,
    id: "10",
    scale: 0.008,
    chance: 5,
  }, {
    name: "春游晚归团扇",
    url: "/assets/models/tuansan02.fbx",
    introduce: '团扇，是中国汉族传统工艺品及艺术品，具有小巧轻盈，温柔飘逸的特点。两宋时期，团扇已成为每个人手中的好物件。上至皇家贵妃，下至黎民百姓',
    position: [0, -0.2, 0],
    rotationX: 2,
    id: "11",
    scale: 0.008,
    chance: 5,
  }, {
    name: "竹鸠团扇",
    url: "/assets/models/tuansan03.fbx",
    introduce: '团扇，是中国汉族传统工艺品及艺术品，具有小巧轻盈，温柔飘逸的特点。两宋时期，团扇已成为每个人手中的好物件。上至皇家贵妃，下至黎民百姓',
    position: [0, -0.2, 0],
    rotationX: 2,
    id: "12",
    scale: 0.008,
    chance: 5,
  }
  ,
]

export default function Home() {

  const [state, setState] = React.useState({
    open: false,
    title: false,
    message: "",
    vertical: 'top',
    horizontal: 'center',
  });
  const [loading, setLoading] = React.useState(false);

  const { vertical, horizontal, open, title, message } = state;

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return(false);
  }

  // 获取object参数
  const object = getQueryVariable("object")
  var objectModel
  if (object) {
    // 查询modelList中，判断id 是不是和object相同
    objectModel = modelList.find(item => item.id == object)
  }
  if (!objectModel) {
    // modelList 上change为概率，按概率随机出一个model    
    var maxChance = 0
    modelList.forEach(item => maxChance += item.chance)
    var random = Math.floor(maxChance * Math.random())
    var luck = 0
    for (var i = 0; i < modelList.length; i++) {
      luck += modelList[i].chance
      if (random <= luck) {
        objectModel = modelList[i]
        break
      }
    }

    if (!objectModel) {
      objectModel = modelList[Math.floor(Math.random() * modelList.length)]
    }
  }

  // 从modelList中随机选择一个模型
  // const [model, setModel] = useState();
  const [model, setModel] = useState(objectModel);
  const [openid, setOpenid] = useState("0");

  axios.get('/api/base/weixinCode', { 
    params: {
      code: getQueryVariable("code"),
      state: getQueryVariable("state"),
    }
  }).then(response => {
    if (response.data.code === 0) {
      setOpenid(response.data.data.token.openid)
    }    
  })

  let navigate = useNavigate();

  const handleClick = (newState) => () => {
    if (openid === '0') {
      setState({ open: true, ...newState, title: false, message: "很抱歉，您尚未登录，请在微信内访问。" });      
      return
    }

    if (loading) {
      return;
    }
    setLoading(() => true);
    const id = model.id
    axios.post('/api/base/acceptObject', {
      openid: openid + "",
      object: id
    }).then(response => {
      if (response.data.code === 0) {
        setState({ open: true, ...newState, title: true, message: "恭喜您领取成功！" });
      } else {
        setState({ open: true, ...newState, title: true, message: "领取失败，您已拥有该藏品！" });
      }

      // 延迟2秒
      setTimeout(() => {
        navigate("../qrcode", { replace: true });
      }, 2000);
    }).catch(error => {
      setState({ open: true, ...newState, title: false, message: "领取失败" });
    })
  };

  const handleClose = () => {
    setState({ ...state, open: false });
    setLoading(false)
  };
  const buttons = (
    <React.Fragment>
      <Button
        onClick={handleClick({
          vertical: 'bottom',
          horizontal: 'center',

        })}
      >
        <Fade
          in={loading}
          style={{
            marginLeft: '-20px'
          }}
        >
          <CircularProgress size={'20px'} color="inherit" />
        </Fade>
        {title ? '已获得' : '获得'}
      </Button>
    </React.Fragment>
  );
  return (
    <div id="main">
      <Canvas shadows dpr={[1, 1]} camera={{ position: [0, 0, 3], fov: 50 }}>
      <Suspense fallback={null}>
        <ambientLight intensity={0.9} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} shadow-mapSize={[512, 512]} castShadow />      
        <Model position={model.position} rotation-x={-Math.PI / model.rotationX} scale={model.scale} name={model.url} />   
        </Suspense>
        <ArcballControls enableZoom={true} enablePan={false}  />
      </Canvas>
      <div className='name'>
        <img src='../image/name.png' />
        <span>{model.name}</span>
      </div>
      <div className='number'>
        <img src='../image/number.png'></img>
      </div>
      <div className='foot'>
        <div className='introduce'>
          <div className='introduceName'>
            <span>数字藏品介绍</span>
            <img src='../image/flowe.png' />
          </div>
          <span>Introduction to digital Collections</span>
        </div>
        <div className='content'>
          {model.introduce}
        </div>
      </div>
      <div className={classNames({
        'btn': true,
        'btns': title
      })} onClick={(event) => handleClick()}>
        {buttons}
      </div>
      <Snackbar
        autoHideDuration={1500}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
        sx={{
        }}

      />
      <div className='background'>
        <img src='../image/Group.png'></img>

      </div>
    </div>
  )
}
